<template>
  <div
    class="menu-power-edit"
    v-loading="loading"
    element-loading-text="拼命加载中..."
    element-loading-spinner="el-icon-loading"
  >
    <el-button :loading="saving" type="primary" size="mini" @click="savePower"
      >保存</el-button
    >

    <div class="menu-tree-div">
      <el-tree
        ref="menuTree"
        class="menu-tree"
        node-key="id"
        check-strictly
        :data="powers"
        :props="{ children: 'children', label: 'name' }"
        show-checkbox
      >
      </el-tree>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";

const { mapGetters } = createNamespacedHelpers("menuStore");

export default {
  name: "menu-power-edit",
  props: {
    roleId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      saving: false,
    };
  },
  computed: {
    // 此处使用当前登录用户拥有的菜单权限，自己没有的权限无法授权给其他用户
    ...mapGetters(["powers"]),
  },
  methods: {
    async refreshPower(roleId) {
      try {
        let data = await this.$http.doApi("fw-cms-role-power-getRolePower", {
          roleId: roleId,
          powerType: 1
        });
        this.$refs.menuTree.setCheckedKeys(data.rows);
      } finally {
        this.loading = false;
      }
    },
    async savePower() {
      try {
        this.saving = true;
        // 选中的菜单
        let checkedKeys = await this.$refs.menuTree.getCheckedKeys();
        await this.$http.doApi(
          "fw-cms-role-power-setRolePower",
          {
            roleId: this.roleId,
            powerType: 1,
          },
          checkedKeys
        );
        this.$message.success("保存成功");
      } finally {
        this.saving = false;
      }
    },
  },
  watch: {
    roleId: {
      immediate: true,
      handler(val) {
        this.refreshPower(val);
      },
    },
  },
};
</script>

<style lang="less">
.menu-power-edit {
  .menu-tree-div {

    max-height: 28rem;
    overflow: scroll;
    overflow-x: hidden;

    .menu-tree {
      margin-top: 1rem;
    }
  }
}
</style>